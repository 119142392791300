var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showArea()
    ? _c("div", { staticClass: "select-recompense" }, [
        _vm.sex
          ? _c("h3", { staticClass: "words" }, [
              _vm._v(" " + _vm._s(_vm.title.split(" ")[0]) + " "),
              _c("span", {
                staticClass: "sex",
                class: _vm.sex,
                domProps: {
                  innerHTML: _vm._s(_vm.title.split(" ").slice(1).join(" ")),
                },
              }),
            ])
          : _vm._e(),
        _vm.sex === undefined
          ? _c("h3", {
              staticClass: "words",
              domProps: { innerHTML: _vm._s(_vm.title) },
            })
          : _vm._e(),
        _vm.subtitle
          ? _c(
              "h4",
              { staticClass: "subtitle" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: "exclamation-triangle" },
                }),
                _vm._v("   "),
                _c("span", { domProps: { innerHTML: _vm._s(_vm.subtitle) } }),
              ],
              1
            )
          : _vm._e(),
        _vm.allDogs.length === 0
          ? _c("h5", { staticClass: "empty" }, [
              _vm._v(_vm._s(_vm.$t("rewards.none_available"))),
            ])
          : _vm._e(),
        _vm.allDogs.length !== 0
          ? _c(
              "ul",
              _vm._l(_vm.allDogs, function (dog) {
                return _c(
                  "li",
                  {
                    key: dog.id,
                    class:
                      "item " +
                      (_vm.selectedValue === dog.nr_cat ? "selected" : ""),
                    on: {
                      click: function ($event) {
                        return _vm.onClick($event, dog)
                      },
                    },
                  },
                  [
                    _c("div", {
                      class: "dog " + (dog.nr_sexe === "1" ? "male" : "female"),
                    }),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(dog.nr_cat)),
                    ]),
                    _c("div", {
                      staticClass: "clazz",
                      domProps: { textContent: _vm._s(dog.lib_classe) },
                    }),
                    _c("div", {
                      staticClass: "qualifiers",
                      domProps: { textContent: _vm._s(dog.qualificatif_label) },
                    }),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }