var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-recompense" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("fil-ariane", { attrs: { navigation: _vm.navigation } }),
        _c("h1", [
          _vm._v(_vm._s(_vm.$t("rewards.title")) + " - "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.getRaceLabel()) } }),
        ]),
        _c(
          "router-link",
          {
            staticClass: "link-to-race",
            attrs: { to: { name: "race", params: { id: this.race_id } } },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "dog" } }),
            _vm._v(" " + _vm._s(_vm.$t("rewards.back_to_race")) + " "),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "recompenses" },
          _vm._l(_vm.getDogsByRewards, function (data, type) {
            return _c("select-recompense", {
              key: type,
              class: type,
              attrs: {
                title: data.title,
                bus: _vm.bus,
                candidates: data.candidates,
                current: data.current,
                reward_id: data.id,
                sex: data.sex,
                dogs: data.dogs,
                value: data.current ? data.current.nr_cat : "",
                subtitle: _vm.getGroupedRewardLabel(data.id),
              },
              on: { "reward-changed": _vm.onChangeReward },
            })
          }),
          1
        ),
        _c("div", { staticClass: "actions" }, [
          _c(
            "button",
            { staticClass: "action cancel", on: { click: _vm.onReset } },
            [_vm._v(_vm._s(_vm.$t("global.reset")))]
          ),
          _vm.showDebug
            ? _c(
                "button",
                { staticClass: "action debug", on: { click: _vm.onDebug } },
                [_vm._v("Debug")]
              )
            : _vm._e(),
          _c(
            "button",
            { staticClass: "action close", on: { click: _vm.onSubmit } },
            [_vm._v(_vm._s(_vm.$t("global.close")))]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }